import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { Grid } from "@material-ui/core";

// core components
import BelAgeNavbar from "components/Navbars/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { useTranslation } from "react-i18next";

function Information() {
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <BelAgeNavbar />
      <div className="wrapper">
        <LandingPageHeader />

        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto " md="8">
                <h2 className="armataBig"> {t("plan.title")}</h2>
              </Col>
            </Row>
            <Row>
              <Grid container spacing="3">
                <Grid item xs={30}>
                  <img
                    src={require(`assets/img/plancapturefromsvg.pngBON.png`)}
                    alt=""
                    className="plan"
                  />
                </Grid>
                <Grid item xs={50}>
                  <div className="description-normal armataSubtitleBlack"> {t("plan.legende")}</div>
                  <br></br>

                  {/* -- bloc sanitaire -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z1</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z1")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* -- buanderie -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z2</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z2")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* -- piscine -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z3</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z3")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* -- jeu de pétanque -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z4</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z4")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* --  Jeu de shuffleboard  -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z5</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                        {" "}
                        {t("plan.z5")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* --  Parc pour enfants -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z6</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z6")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* --  Abri de pique-nique -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z7</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z7")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* --  Air de détente -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z8</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z8")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* --  Air de jeu -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z9</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z9")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* --  Stationnement -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z10</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z10")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* --  Déchet et recyclage -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z11</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z11")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* -- Station de vidange et eau potable -- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z12</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z12")}
                      </div>
                    </Grid>
                  </Grid>

                  {/* -- Salle communautaire-- */}
                  <Grid container spacing="1">
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">Z13</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">-</div>
                    </Grid>
                    <Grid item xs={15}>
                      <div className="description-normal armataSmall">
                      {t("plan.z13")}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default Information;
