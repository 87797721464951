import backendApi from "../api/AxiosAPIUtils";

export const getAll = () => {
  console.log("entre getAll...");
  return backendApi.get(`/prices/`);

};

export const get = id => {
  return backendApi.get(`/prices/${id}`);
};

export const create = data => {
  return backendApi.post("/prices/", data);
};

export const update = (id, data) => {
  return backendApi.put(`/prices/${id}/`, data);
};

export const remove = id => {
  return backendApi.delete(`/prices/${id}/`);
};

export const removeAll = () => {
  return backendApi.delete(`/prices`);
};

export const findByName = name => {
  return backendApi.get(`/prices?name=${name}`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default getAll;
