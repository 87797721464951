import axios from "axios";
import * as Constants from "../constants/index";

const backendApi = axios.create({
  baseURL: Constants.API_URL
});

export default backendApi;



