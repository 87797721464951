import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import CarouselService from '../../../services/CarouselService';
import './Carousel.css';

const CarouselPhoto = () => {
    const [products, setProducts] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const carouselService = new CarouselService();

    useEffect(() => {
        carouselService.getProductsSmall().then(data => setProducts(data.slice(0,22)));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const productTemplate = (product) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="p-mb-3">
                        <img src={require(`assets/img/gallery/${product.image}`)}  alt={product.name} className="product-image" />
                    </div>
                    
                </div>
            </div>
        );
    }

    return (
        <div className="carousel-demo">
            <div className="card">
                <Carousel value={products} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions}  circular
                    autoplayInterval={3000} itemTemplate={productTemplate}  />
            </div>
        </div>
    );
}
                
export default CarouselPhoto;