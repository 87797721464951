import axios from "axios";
import * as Constants from "../constants/index";

// set a CSRF token automaticaly
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
// very important, otherwise CSFR cookie not set problem.
axios.defaults.withCredentials = true

async function sendEmail(name, email, subject, message)  {
    console.log(name + ':' + email + ':' + subject + ':' + message + ':')
    await axios({
      method: "POST",
      url: Constants.MAIL_URL,
      data: {
        'name': name,
        'subject': subject,
        'email': email,
        'message': message
      },
    }).then(response => {
      return response;
  }).catch(({response}) => {
      console.log("mailer catch");
      return response;
  }).finally(() => {
      console.log("mailer finally");
  });
  };

  export default sendEmail;

 

