import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function BelageNavbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    
    i18n.changeLanguage(lng);
  };
  

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container>
          <UncontrolledDropdown className="button-dropdown">
            <DropdownToggle
              caret
              data-toggle="dropdown"
              href="#pablo"
              id="navbarDropdown"
              tag="a"
              onClick={(e) => e.preventDefault()}
            >
              <span className="button-bar"></span>
              <span className="button-bar"></span>
              <span className="button-bar"></span>
            </DropdownToggle>
            <DropdownMenu aria-labelledby="navbarDropdown">
              <DropdownItem header tag="a">
              {t('navigation.quick')}
              </DropdownItem>
              <DropdownItem to="/index" tag={Link}>
              {t('navigation.accueil')}
              </DropdownItem>
              <DropdownItem to="/photoGallery" tag={Link}>
              {t('navigation.gallery')}
              </DropdownItem>
              <DropdownItem to="/information" tag={Link}>
              {t('navigation.services')}
              </DropdownItem>
              <DropdownItem to="/prix" tag={Link}>
              {t('navigation.prix')}
              </DropdownItem>
              <DropdownItem to="/plan" tag={Link}>
              {t('navigation.plan')}
              </DropdownItem>
              {/* <DropdownItem divider></DropdownItem> */}
              <DropdownItem to="/contact" tag={Link}>
              {t('navigation.coordonnees')}
              </DropdownItem>
              {/* <DropdownItem divider></DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>

          <div className="navbar-translate">
            <NavbarBrand
              href="#"
              target="_blank"
              id="navbar-brand"
            >
               
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Choix rapide du contenu disponible
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink to="/index" tag={Link}>
                {t('navigation.accueil')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/photoGallery" tag={Link}>
                {t('navigation.gallery')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/information" tag={Link}>
                {t('navigation.services')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/prix" tag={Link}>
                {t('navigation.prix')}
                </NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink to="/plan" tag={Link}>
                {t('navigation.plan')}
                </NavLink>
              </NavItem>


              <NavItem>
                <NavLink to="/contact" tag={Link}>
                  {t('navigation.coordonnees')}
                </NavLink>
              </NavItem>
              
               <NavItem>
                <NavLink
                  href="https://twitter.com/Campingbelage"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i className="fab fa-twitter"></i>
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem> 
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/campingdomainedubelage"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
             <NavItem>
                <NavLink
                  href="https://www.instagram.com/CampingDomaineDuBelAge/?hl=fr-ca"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem> 
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => changeLanguage('fr')}
                  target="_blank"
                >
                  <img src={require(`assets/img/flags/FR.png`)} alt="fr" className="img-icon" ></img>
                </NavLink>
              </NavItem> 
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => changeLanguage('en')}
                  target="_blank"
                >
                  <img src={require(`assets/img/flags/US.png`)} alt="en" className="img-icon" ></img>
                </NavLink>
              </NavItem> 
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => changeLanguage('es')}
                  target="_blank"
                >
                  <img src={require(`assets/img/flags/ES.png`)} alt="es" className="img-icon" ></img>
                </NavLink>
                
              </NavItem> 
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => changeLanguage('jp')}
                  target="_blank"
                >
                  <img src={require(`assets/img/flags/JP.png`)} alt="en" className="img-icon" ></img>
                </NavLink>
              </NavItem> 



            </Nav>
            {/* <button type="button" ><img src={require(`assets/img/flags/FR.png`)} alt="fr" onClick={() => changeLanguage('fr')}  className="img-icon" ></img></button>
            <button type="button" ><img src={require(`assets/img/flags/US.png`)} alt="en" onClick={() => changeLanguage('en')}  className="img-icon" ></img></button>
            <button type="button" ><img src={require(`assets/img/flags/ES.png`)} alt="es" onClick={() => changeLanguage('es')}  className="img-icon" ></img></button>
            <button type="button" ><img src={require(`assets/img/flags/JP.png`)} alt="jp" onClick={() => changeLanguage('jp')}  className="img-icon" ></img></button> */}
          </Collapse>
        </Container>
      </Navbar>

    </>
  );
}

export default BelageNavbar;
