import React, { useState, useEffect } from "react";
import { get } from "../../services/PriceService";

// reactstrap components
import { Container, Row, Col } from "reactstrap";


// core components
import BelAgeNavbar from "components/Navbars/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { useTranslation } from "react-i18next";

const Location = () =>  {
  const [t] = useTranslation();
  const [priceBonair, setPriceBonair] = useState([]);
  const [priceJayco, setPriceJayco] = useState([]);
  console.log("entre dans prix...");

  useEffect(() => {
    console.log("entre dans useEffect...");

    get(4)
      .then((resp) => {
        console.log(resp);
        setPriceBonair(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    get(5)
      .then((resp) => {
        console.log(resp);
        setPriceJayco(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <BelAgeNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="armataBig">{t("location.bonair")}</h2>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto " md="8">
                <div className="description-normal armataSmall">
                 
                 
                 
                  
                  <div> <img src={require(`assets/img/roulottes/bonair1.JPG`)}  alt="roulotte bonair" className="roulotte" /> 
                  <img src={require(`assets/img/roulottes/bonair4.JPG`)}  alt="roulotte bonair" className="roulotte" />      
                   <img src={require(`assets/img/roulottes/bonair3.JPG`)}  alt="roulotte bonair" className="roulotte" />     
                   <img src={require(`assets/img/roulottes/bonair5.JPG`)}  alt="roulotte bonair" className="roulotte" />    
                   <img src={require(`assets/img/roulottes/bonair2.JPG`)}  alt="roulotte bonair" className="roulotte" />        </div>
                 
               
                <div>
                <p></p>
                <p className="description-normal armataSmall"> {t("location.descriptionbonair1")}.</p>
                <p className="description-normal armataSmall"> {t("location.descriptionbonair2")}.</p>
                </div>

                <p className="armataSubtitleBlack">{t("location.locationsemainelabel")}: {priceBonair.pricemin} {t("location.dollar")}  </p> 
                <p className="armataSubtitleBlack">{t("location.locationmoislabel")}: {priceBonair.pricemax} {t("location.dollar")}  </p> 

                
                </div>
                
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto " md="8">
                <h2 className="armataBig">{t("location.jayco")}</h2>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto"  md="8">
                <div className="description-normal armataSmall">
                 
                  <div> <img src={require(`assets/img/roulottes/jayco4.JPG`)}  alt="roulotte bonair" className="roulotte" /> 
                  <img src={require(`assets/img/roulottes/jayco5.JPG`)}  alt="roulotte bonair" className="roulotte" />      
                   <img src={require(`assets/img/roulottes/jayco1.JPG`)}  alt="roulotte bonair" className="roulotte" />     
                   <img src={require(`assets/img/roulottes/jayco2.JPG`)}  alt="roulotte bonair" className="roulotte" />    
                   <img src={require(`assets/img/roulottes/jayco3.JPG`)}  alt="roulotte bonair" className="roulotte" />        </div>
                 
                <div>
                <p></p>
                <p className="description-normal armataSmall"> {t("location.descriptionjayco1")}.</p>
                <p className="description-normal armataSmall"> {t("location.descriptionjayco2")}.</p>
                </div>

                <p className="armataSubtitleBlack">{t("location.locationsemainelabel")}: {priceJayco.pricemin} {t("location.dollar")}  </p> 
                <p className="armataSubtitleBlack">{t("location.locationmoislabel")}: {priceJayco.pricemax} {t("location.dollar")}  </p> 
                </div>
                <br></br>

                <hr></hr>
               

               
              </Col>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto " md="8">
                <h2 className="armataBigBlack" >{t("location.periode")}</h2>
              </Col>
            </Row>

              
            <Row>
             
              <Col className="ml-auto mr-auto" md="7">
              <div className="description-normal armata">
                 
                  <p><i>{t("location.avertissement")}.</i></p>
                </div>
              </Col>
            </Row>
            

            
            

            
            <Row>
            
              <Col className="ml-auto mr-auto" md="8">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              </Col>
            </Row>
            

          


          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default Location;
