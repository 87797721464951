
import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import sendEmail from "../../services/Mailer";
import CSRFToken from "components/Utils/CSRFToken";


const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch"
    }
  }
}));



function ContactForm() {
    const [sent, setSent] = useState(false);
    const { t} = useTranslation();
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessages, setErrorMessages] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    let errors = [];

    async function sendMessage()  {
      setSent(false);
      await sendEmail(name, email, subject, message);
      setSent(true);
      resetFields();
    };

    function ValidateEmail(email) {
      setSent(false);
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    }
    
    const formValidation = () => {
      setErrorMessages([]);
  
      const isNameValid = name !== "";
      const isMessageValid = message !== "";
      const isSubjectValid = subject !== "";
      
  
      if (!isNameValid) {
        errors.push(t('contact.nameerlb'));
      }
      if (!ValidateEmail(email)) {
        errors.push(t('contact.emailnvlb'));
      }
      if (email === "") {
        errors.push(t('contact.emailerlb'));
      }
      if (!isMessageValid) {
        errors.push(t('contact.messageerlb'));
      }
      if (!isSubjectValid) {
        errors.push(t('contact.sujeterlb'));
      }
      if (errors.length > 0) {
        setShowErrors({ showErrors: true });
        setErrorMessages(errors);
      } else {
        setShowErrors({ showErrors: false });
        // send mail info
        sendMessage(name, email, subject, message);
      }
    };

    function resetFields() {
      console.log("passe resetFields");
      setName("");
      setEmail("");
      setMessage("");
      setSubject("");
    }

    return (
      
        <div className="formemailcontainer">
          <div className="innerFormContainer">
          <div >
          <p>{t('accueil.camping')}</p>
          <p>{t('contact.addresse')}</p>
          <p>{t('contact.telephone')}</p>
          <p>{t('contact.emailaddresse')}<br></br>
          </p>
        
          </div>
            <form className={classes.root} id="formMail">
              <CSRFToken></CSRFToken>
	      <TextField
                label={t('contact.namelb')}
                placeholder={t('contact.name')}
                type="text"
                variant="outlined"
                onChange={e => setName(e.target.value)}
                value={name}
              />
              <TextField
                label={t('contact.emaillb')}
                placeholder={t('contact.email')}
                type="email"
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
              <TextField
                label={t('contact.sujetlb')}
                placeholder={t('contact.sujet')}
                type="text"
                variant="outlined"
                onChange={e => setSubject(e.target.value)}
                value={subject}
              />
              <TextField
                label={t('contact.messagelb')}
                placeholder={t('contact.message')}
                type="text"
                variant="outlined"
                multiline
                rowsMax="3"
                onChange={e => setMessage(e.target.value)}
                value={message}
              />
              {showErrors
                ? errorMessages.map((item, index) => {
                    return <ul key={index}>{item}</ul>;
                  })
                : null}
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={formValidation}
              >
                {t('contact.envoie')}
              </Button>
              {sent
                ?  <><p className="sent">{t('contact.sent')}</p><br></br></>
                : null}
             
            </form>
          </div>
        </div>
    );
    
                
}


export default ContactForm;
