/*

=========================================================
* Now UI Kit React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


// pages for this kit
import Index from "views/Index.js";

import PhotoGallery from "./views/index-sections/PhotoGallery.js";
import Information from "views/index-sections/Information.js";
import Prix from "views/index-sections/Prix.js";
import Contact from "views/index-sections/Contact.js";
import Plan from "views/index-sections/Plan.js";
import Location from "views/index-sections/Location.js";
import "./i18n";

ReactDOM.render(
  
  <Suspense fallback={<h1>Loading profile...</h1>}>
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route
          path="/photoGallery"
          render={(props) => <PhotoGallery {...props} />}
        />
        <Route
          path="/information"
          render={(props) => <Information {...props} />}
        />
        <Route
          path="/prix"
          render={(props) => <Prix {...props} />}
        />
        <Route
          path="/contact"
          render={(props) => <Contact {...props} />}
        />
         <Route
          path="/plan"
          render={(props) => <Plan {...props} />}
        />

        <Route
          path="/location"
          render={(props) => <Location {...props} />}
        />

        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
