/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          
            
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, {" "}
            <a
              href="https://www.campingbelage.com"
              target="_blank"
            >
              Camping Le Domaine du Bel-Âge
            </a>
            . Coded by{" "}
            <a href="mailto:mael.pilon-joly@hotmail.com">Maël Pilon-Joly</a>
	    .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
