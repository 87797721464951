import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import BelAgeNavbar from "components/Navbars/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { useTranslation } from "react-i18next";

function Information() {
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <BelAgeNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="armataBig"> {t("services.title")}</h2>
              </Col>
            </Row>
            
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/electric.svg`)}
                    alt="electric"
                    className="img-icon"
                  />{" "}
                  {t("services.electricite")}
                </div>
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/wifi.svg`)}
                    alt="wifi"
                    className="img-icon"
                  />{" "}
                  {t("services.wifi")}
                </div>
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/sun.png`)}
                    alt="soleil"
                    className="img-icon"
                  />{" "}
                  {t("services.saison")}
                </div>
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/tent.png`)}
                    alt="tente"
                    className="img-icon"
                  />{" "}
                  {t("services.tente")}
                </div>
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/snowflake.png`)}
                    alt="hiver"
                    className="img-icon"
                  />{" "}
                  {t("services.vr")}
                </div>
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/shower.png`)}
                    alt="hiver"
                    className="img-icon"
                  />{" "}
                  {t("services.douche")}
                </div>
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/wd.png`)}
                    alt="hiver"
                    className="img-icon"
                  />{" "}
                  {t("services.buanderie")}
                </div>
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/log.png`)}
                    alt="hiver"
                    className="img-icon"
                  />{" "}
                  {t("services.bois")}
                </div>
                <div className="description-normal armataSmall">
                  <img
                    src={require(`assets/img/poop.png`)}
                    alt="hiver"
                    className="img-icon"
                  />{" "}
                  {t("services.vidange")}
                </div>

              </Col>
            </Row>

	    <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="armataBig"> {t("services.title3")}</h2>
              </Col>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto" md="8">



                <div className="description-normal-service armataSmall">
                  {t("services.lc.wifi")}
                </div>
                <div className="description-normal-service armataSmall">
                  {t("services.lc.cuisine")}
                </div>

                <div className="description-normal-service armataSmall">
                  {t("services.lc.piscine")}
                </div>
                
                <br/>
                <div className="description-normal-service armataSmall">
                  {t("services.lc.welcome")}
                </div>

              </Col>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="armataBig"> {t("services.title2")}</h2>
              </Col>
            </Row>
            
            <Row>
              <Col className="ml-auto mr-auto" md="8">

               
               
                <div className="description-normal-service armataSmall">
                  {t("services.biblio")}
                </div>
                <div className="description-normal-service armataSmall">
                  {t("services.billard")}
                </div>
               
                <div className="description-normal-service armataSmall">
                  {t("services.petanque")}
                </div>
                <div className="description-normal-service armataSmall">
                  {t("services.parc")}
                </div>
                <div className="description-normal-service armataSmall">
                  {t("services.cyclo")}
                </div>
                <div className="description-normal-service armataSmall">
                  {t("services.golf")}
                </div>

              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default Information;
