import React from "react";


// reactstrap components
import {

  Container,
  Col,
} from "reactstrap";



// core components
import BelAgeNavbar from "components/Navbars/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import ContactForm from "components/Forms/ContactForm.js";

function Contact() {
  

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <BelAgeNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
          <Col className="ml-auto mr-auto" md="8">
          <ContactForm></ContactForm>
          </Col>
          </Container>
          <DefaultFooter />
        </div>
      </div>
    </>
  );
}

export default Contact;
