
import axios from 'axios';

export default class CarouselService {

    getProductsSmall() {
        return axios.get('./data/product-small.json',{
            headers: {
                'Content-Type': 'application/json'
            }}).then(res => res.data.data);
    }

    getProducts() {
        return axios.get('./data/products.json',{
            headers: {
                'Content-Type': 'application/json'
            }}).then(res => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get('./data/products-orders-small.json',{
            headers: {
                'Content-Type': 'application/json'
            }}).then(res => res.data.data);
    }
}
    