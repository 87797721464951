import React from "react";



// reactstrap components
import {

  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import BelAgeNavbar from "components/Navbars/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import './index.css'
import { useTranslation } from 'react-i18next';


function Index() {
  const { t, i18n } = useTranslation();


  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <BelAgeNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="LobsterLargeRed">  {t('accueil.camping')}</h2>
                <h2 className="armataBig"> {t('accueil.pres')}</h2>
            
                <h5 className="armataSmall">
                {t('accueil.paragraph1')}
                </h5>
                <h5 className="armataSmall">
                {t('accueil.paragraph2')}
                </h5>
                <h5 className="armataSmall">
                {t('accueil.paragraph3')}
                </h5>
                <div>
                <img
                    src={require(`assets/img/nancylogo.jpg`)}
                    alt="location"
                    className="logonancy"
                  />
                </div>
		<div><h5 className="armataSmall">
	  {t('accueil.numero1')} <br></br>
	  {t('accueil.numero2')}<br></br>
	  {t('accueil.numero3')}
                </h5>
	       </div>

              </Col>
            </Row> 
          </Container>
        </div>
         <DefaultFooter /> 
      </div>
    </>
  );
}

export default Index;
