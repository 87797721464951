import React from "react";
import  CarouselPhoto  from '../Primefaces/Carousel/CarouselPhoto';
// reactstrap components
import { Container } from "reactstrap";
import "assets/css/belage.css";

function Photos() {
  return (
    <>
     
      <div className="section section-nucleo-icons">
      
        <Container>
          
            <CarouselPhoto></CarouselPhoto>


         


          
          
        </Container>
      </div>
    </>
  );
}

export default Photos;
