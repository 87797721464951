import React, { useState, useEffect } from "react";
import { get } from "../../services/PriceService";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import BelAgeNavbar from "components/Navbars/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { useTranslation } from "react-i18next";

const Prix = () => {
  const [t] = useTranslation();
  const [priceTent, setPriceTent] = useState([]);
  const [priceRV, setPriceRV] = useState([]);
  const [priceSaison, setPriceSaison] = useState([]);
  console.log("entre dans prix...");

  useEffect(() => {
    console.log("entre dans useEffect...");
    get(3)
      .then((resp) => {
        console.log(resp);
        setPriceTent(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    get(2)
      .then((resp) => {
        console.log(resp);
        setPriceSaison(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    get(1)
      .then((resp) => {
        console.log(resp);
        setPriceRV(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <BelAgeNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto " md="8">
                <h2 className="armataBig">{t("prix.nuit")}</h2>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <div className="description-normal armataSmall">
                  {t("prix.tente")}
                  {priceTent.pricemin}
                  {t("prix.a")}
                  {priceTent.pricemax}
                  {t("prix.dollar")}
                </div>
                <div className="description-normal armataSmall">
                  {t("prix.vr")}
                  {priceRV.pricemin}
                  {t("prix.a")}
                  {priceRV.pricemax}
                  {t("prix.dollar")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="armataBig">{t("prix.saison")}</h2>
                <p className="armataMediumBlack">{t("prix.periode")}</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <div className="description-normal armataSmall">
                  {t("prix.prixsaison")}
                  {priceSaison.pricemin}
                  {t("prix.a")}
                  {priceSaison.pricemax}
                  {t("prix.dollar")}
                </div>
              </Col>
            </Row>

            
            <Row>
            
              <Col className="ml-auto mr-auto" md="8">
              <br></br>
            <hr></hr>
            <br></br>           
                <p className="armataMediumBlack">{t("prix.cartes")}</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <div className="description-normal armataSmall">
                <img
                    src={require(`assets/img/visamaster.png`)}
                    alt="location"
                    className="cartes"
                  />
                  <img
                    src={require(`assets/img/interac.png`)}
                    alt="location"
                    className="interac"
                  />
                </div>
               
                
              </Col>
            </Row>

          


          </Container>
        </div>

        <DefaultFooter />
      </div>
    </>
  );
};

export default Prix;
